<template>
  <div class="warp">
    <div class="title" v-if="detail.msgTitle">{{ detail.msgTitle }}</div>
    <div class="time" v-if="detail.createTime">{{ timestampReturnDate(detail.createTime, 'datetime') }}</div>
    <div class="content" v-if="detail.msgContent" v-html="detail.msgContent">同学你好，英语(助考1)课程的测试123课次将于2022-05-01 17:55进行直播，请及时查看并点击下方按键确认已知晓。</div>
    <div class="rightExam" @click="rightExamEvent" v-if="detail.msgInnerChain">
      立即前往
    </div>
    <div class="check" @click="handleCheck">
      确认并返回
    </div>
  </div>
</template>

<script>
import way from '../../api/encapsulation'
export default {
  name: 'messageDetail',
  data () {
    return {
      detail: {},
      studentId: '24640'
    }
  },
  watch: {
    '$store.state.isGetToken'(val) {
      let type = this.$store.state.deviceType
      if (val && type === 1) {
        // ios
        this.getDetail()
        window.webkit.messageHandlers.getData.postMessage('studentId')
        window.getIosStudentId = this.getIosStudentId
      } else if (val && type === 2) {
        // 安卓
        this.getDetail()
        this.getAnStudentId()
      } else {
        // console.log('本地测试')
        this.getDetail()
        this.studentId = 24640
      }
    }
  },
  created () {
    let val = sessionStorage.getItem('token')
    let type = this.$store.state.deviceType
    console.log(type,77777);
    if (val && type === 1) {
      // ios
      this.getDetail()
      window.webkit.messageHandlers.getData.postMessage('studentId')
      window.getIosStudentId = this.getIosStudentId
    } else if (val && type === 2) {
      // 安卓
      this.getDetail()
      this.getAnStudentId()
    } else if (val) {
      this.getDetail()
      this.studentId = 24640
    }
  },
  beforeDestroy() {
    let that = this
    document.querySelectorAll('a').forEach(e => {
      e.removeEventListener('click', function (event) {
        that.addEvent(event)
      })
    })
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 安卓获取学生id
    getAnStudentId () {
      this.studentId = window.Android.getStudentId()
    },
    // ios获取学生id
    getIosStudentId (studentId) {
      this.studentId = parseInt(studentId)
      // alert('this.studentId:' + this.studentId)
    },
    // 获取信息详情
    getDetail () {
      let messageId = this.$route.query.messageId
      this.$request.getMessageDetail({ id: messageId }).then((res) => {
        this.detail = res.data.data
        this.$nextTick(() => {
          this.linstenA()
        })
      }).catch((err) => {
        
      });
    },
    // a标签跳转监听
    linstenA() {
      let that = this
      let nodeList = document.querySelectorAll('a')
      if (nodeList.length == 0) return;
      nodeList.forEach(e => {
        e.addEventListener('click', function (event) {
          that.addEvent(event)
        })
      })
    },
    addEvent(event) {
      var target = event.target || event.srcElement;
      if (target.nodeName.toLocaleLowerCase() === "a") {
        if (event.preventDefault) {     // 对捕获到的 a 标签进行处理
            event.preventDefault()
        } else {
            event.returnValue = false
        }
        console.log(target.href, 778);
        this.downEvent(target.href)
      }
    },
    downEvent(fUrl) {
      if(this.$store.state.deviceType == 1)
        window.webkit.messageHandlers.gotoDownload.postMessage(fUrl)
      else if(this.$store.state.deviceType == 2)
        window.Android.outWeb(fUrl)
    },
    handleCheck () {
      let id = this.$route.query.messageId
      this.$request.getMessageCheck({id}).then((res) => {
        let type = this.$store.state.deviceType
        if (type === 1) {
          // alert('ios返回')
          if (window.history.length <= 1) {
            // console.log('无上一页' + window.history.length)
            window.webkit.messageHandlers.getData.postMessage('close()')
          } else {
            this.$router.go(-1)
          }
        } else if (type === 2) {
          if (window.history.length <= 1) {
            // console.log('无上一页' + window.history.length)
            window.Android.Close()
          } else {
            this.$router.go(-1)
          }
        } else {
          this.$router.go(-1)
        }
      }).catch((err) => {
        
      });
    },
    // 立即前往
    rightExamEvent() {
      let appType = this.$store.state.deviceType
      let type = this.detail.msgInnerChain
      let id = this.$route.query.messageId
      this.$request.getMessageCheck({id}).then((res) => {
        if(appType == 1) {
          window.webkit.messageHandlers.gotoAppPage.postMessage(type)
        } else if(appType == 2) {
          window.Android.gotoMain(type)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.warp {
  padding: 12px 17px;
  background: #F9F9F9;
  height: inherit;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  overflow: scroll;
  .title {
    font-size: 20px;
    color: #333333;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .time {
    font-size: 14px;
    color: #999999;
    line-height: 14px;
    padding: 14px 0;
  }

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding-bottom: 250px;
  }

  .check, .rightExam {
    color: #1A7EE2;
    border-radius: 30px;
    border: 2px solid #1A7EE2;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    height: 44px;
    line-height: 44px;
    position: fixed;
    bottom: 100px;
    left: 25%;
    background: #FFFFFF;
  }

  .rightExam {
    bottom: 164px;
  }
}
</style>